:root {
  --color-primary: #4CF087;
  --color-secondary: #34B4C1;
  --color-accent: #1B77F8;
  --color-text: #000000;
  --color-bg: #000000;
  --color-light-text: #CBD5E0;
  --color-link: #03d189;
  --color-link-hover: #34B4C1;
  --font-size-base: calc(10px + 1vmin);
}

body, .App {
  background-color: var(--color-bg);
  color: var(--color-text);
  font-size: var(--font-size-base);
  margin: 0;
  text-align: center;
}

body {
  overflow-x: hidden;
}

body, html {
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  border-radius: 90%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 30s linear;
  }
}

.NavBar {
  background: linear-gradient(0.25turn, #4CF087, #34B4C1, #1B77F8);
  align-items: right;
  display: flex;
  position: fixed; /* This will make it stick to the top */
  height: 1.5rem;
  max-height: 2rem;
  top: 0; /* This will position it at the top of the screen */
  width: 100%; /* This will make it span the full width of the viewport */
  z-index: 1;
}

.hamburger {
  margin: 0.4rem 0rem 0.25rem 0.25rem;
  justify-content: right;
}

@media screen and (max-width: 1000px) {
  .hamburger {
    margin: -0.1rem 12rem 0.25rem 0.25rem;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 400px) {
  .hamburger {
    margin: -0.1rem 12rem 0.25rem 0.25rem;
    overflow-x: hidden;
  }
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #03d189;
}

.App-link:hover {
  color:  #34B4C1;
  cursor: pointer;
}

.main_text {
  font-size: calc(8px + 1vmin);
}

.text-secondary {
  color: black;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.highlight-green {
  border-radius: 1.5em 0 1.5em 0;
  background-image: linear-gradient(
    -270deg,
    rgba(0,255, 20, 0.10),
    rgb(30, 187, 30) 92%,
    rgba(0, 255, 20, 0.05)
  );
}

.button {
  background: linear-gradient(0.25turn, #4CF087, #34B4C1, #1B77F8);
  background-color: #686869;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: calc(2px + 2vmin);
  color: white;
  border: 1px solid #03d189;
  margin: 0.5rem;
  cursor: pointer;
  transition-duration: 0.4s;
  font-weight: bolder;
  border-radius: 10px;
}

.button:hover {
  color: white;
  box-shadow: -0.25em 0.25em .15em #686869;
  
}

.button span{
	display: block;
	position: relative;
	transition:0.3s ease-in-out all;
	transform-origin:50% 0;
  transform-style:preserve-3d;
	color: white;
  width: 100%;
  

}
.button span:after{
	display: block;
	content: attr(data-title);
	position: absolute;
  padding-left: 0.5rem;
  text-align: center;
	left: 0;
	top: 0;
	transition:0.3s ease-in-out all;
	transform-origin:50% 0;
	transform:translate3d(0px,105%,0px) rotateX(-90deg);
	color: white;
  font-weight: bold;
  font-size: calc(12px + 0.75vmin);
  border-radius: 25px;
  background-image: linear-gradient(-180deg, #ff0022 0%, #5c0113 100%);
}


.button:hover span{
  transform:translate3d(0px,0px,-30px) rotateX(90deg);
}

.button:active {
  color: white;
  font-weight: bold;
  border-radius: 25px;
  transform: translate(0.25em,-0.25em);
}

.blue {
  background: #000dff;
  color: white;
  border: 1px solid #000dff;
  font-weight: bold;
  border-radius: 20px;
  border: 1px solid #012880;
	background-image: linear-gradient(-180deg, #000dff 0%, #0050fc 100%);
	box-shadow: 0 0.15rem .25rem 0 rgba(11, 47, 131, 0.5),
							0 -0.25rem 0.5rem rgb(21, 6, 186) inset,
							0 0.25rem 0.15rem rgba(255,255,255, 0.4) inset,
							0 0.25rem 0.5rem 0 rgb(70, 155, 207) inset;
}

.red {
  color: white;
  border: 1px solid #800101;
  font-weight: bold;
  border-radius: 20px;
  background-image: linear-gradient(-180deg, #ff0022 0%, #5c0113 100%);
	box-shadow: 0 0.15rem .25rem 0 rgba(79, 9, 9, 0.5),
							0 -0.25rem 0.5rem rgb(186, 24, 6) inset,
							0 0.25rem 0.15rem rgba(255,255,255, 0.4) inset,
							0 0.25rem 0.5rem 0 rgb(207, 70, 88) inset;
}

@media screen and (min-width: 769px) {
  .red {
    margin-top: 1.25rem;
    margin-right: 13.75rem;
  }
}

@media screen and (max-width: 768px) {
  .red {
    margin-top: 0.5rem;
    margin-right: 8.75rem;
  }
}

@media screen and (min-width: 769px) {
  .blue {
    margin-top: 1.25rem;
    margin-right: 0.35rem;
  }
}

@media screen and (max-width: 768px) {
  .blue {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
  }
}

.table {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: calc(10px + 0.75vmin);
  padding: 0.25px;
  min-width: 50vw;
  color: white;
  font-weight: bold;
  border: 1px solid #03d189; 
}

.tableText {
  color: rgb(225, 225, 225);
  font-weight: bold;
  align-items: center;
  margin: auto;
  padding: 0.75rem;
}

.highlight {
  font-size: calc(10px + 1vmin);
  color: #CBD5E0;
}

.highlight-green {
  border-radius: 1.5em 0 1.5em 0;
  background-image: linear-gradient(
    -270deg,
    #133c22,
    #195a62 92%,
    #1B77F8
  );
}



.highlight-greenblue {
  border-radius: 1.5em 0 1.5em 0;
  background-image: linear-gradient(
    -270deg,
    rgba(27,119,248, 0.15),
    #34B4C1 80%,
    rgba(76,240,135, 0.05)
  );
  font-size: calc(32px + 1vmin);
  font-weight: bolder
}

.Box {
  padding: 1.5rem 1rem 1.5rem 1rem;
  border-color: #182523;
  background-color: #171923;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  min-width: 50vw;
  min-height:55vh;
  font-size: calc(10px + 1vmin);
  color: #CBD5E0;
}

.title {
  text-decoration: underline;
  display: inline-flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  padding: 0 2rem 0 2rem;
  color: white;
  margin: 0 10px;
}

.column {
  flex-direction: column;
  align-items: left;
  justify-content: left;
  text-align: left;
  padding: 0 2rem 0 2rem;
  color: white;
  margin: 0 10px;
  border: 1px solid #2e3246;
}

.weekBox {
  width: 14px;
  height: 14px;
  background-color: #FF0000;
  margin: 1px;
  display: flex;
  align-items: center;
  color: #000000;
}

.weekBox:hover span{
  color: white;
  background-color: #000000;
  overflow: visible;
  white-space: wrap;
  justify-content: center;
  font-weight: bolder;
  font-size: calc(8px + 1vmin);
  z-index: 1;
}

.progress {
  width: 100%;
  height: 10px;
  margin-top: 10px;
}

.progress::-webkit-progress-bar {
  background-color: #ffffff;
}

.progress-container {
  display: flex;
  align-items: center;
}

.progress-text {
  margin-left: 5px;
}

.credits {
  text-align: left; /* Align contents to the left */
  max-width: 70%;
  font-size: calc(8px + 1vmin);
  margin: 1rem  auto;
}
.credits-center {
  text-align: center; 
  font-size: calc(8px + 1vmin);
  margin: 1rem  auto;
  display: block;
}

.skinny {
  max-width: 70%;
}

.lived {
  width: 14px;
  height: 14px;
  background-color:linear-gradient(to bottom right, #4cf087, #1b77f8);
  margin: 1px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 1rem;
}

.image-item {
  overflow: hidden;
  cursor: pointer;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-photo {
  background: center / contain no-repeat url('./assets/mj_sw.png');
  border-radius: 5.35rem 5.35rem 4.5rem 4.5rem;
  height: 10rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  width: 10rem;
  background-color: transparent;
}

.profile-photo:hover {
  background: center / contain no-repeat url('./assets/mj_ch.jpeg');
  border-radius: 5rem;
  height: 10rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  width: 10rem;
  background-color: transparent;
  cursor: crosshair;
}

.morpheus {
  background: center / contain no-repeat url('./assets/morpheus3.jpg');
  border-radius: 0rem;
  height: 30rem;
  margin: 0 auto;
  padding-top: 20.5rem ;
  width: 30rem;
  background-color: transparent;
}

@media only screen and (max-width: 768px) {
  .morpheus {
    height: 20rem;
    padding-top: 14rem;
    width: 20rem;
  }
}

.Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  position: fixed;
  margin-top: 3rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}


.Modal .modal-content {
  background-color: #282c34;
  color: white;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.modal-header {
  display: flex;
  align-items: left;
  height: 10%;
  color: white;
  font-style: bolder;
}

.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 70vh;
}

.modal-body img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  max-height: 65vh;
}

.modal-footer {
  display: flex;
  align-items: left;
  height: 10%;
  color: white;
  font-style: bolder;
}

.modal-prev-arrow, .modal-next-arrow {
  position: absolute;
  top: 50%;
  font-size: 2rem;
  padding: 2rem;
  color: #fff; 
  opacity: 1;
  cursor: pointer;
}

.modal-prev-arrow {
  left: 0;
  transform: translateY(-50%);
}

.modal-next-arrow {
  right: 0;
  transform: translateY(-50%);
}

button.close {
  color: #fff; 
  opacity: 1;
  cursor: pointer;
}

.header-text {
  display: flex;
  align-items: left;
  height: 10%;
  color: black;
  font-style: bolder;
}

.image-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.timeline-bullet-points {
  list-style-type: disc;
  display: list-item;
  margin-left: 1.5rem;
  margin-bottom: 0.25rem;
  align-items: left;
  text-align: left;
}

.vertical-timeline-element {
  margin-top: 40px;
}

.custom-icon > svg {
  width: 40px; 
  height: 40px;
  border-radius: 50%;
}

.particles-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

}

.code-block {
  width: 40%;
  margin: 0 auto;
  display: inline-block;
  justify-content: center;
  font-weight: bolder;

}